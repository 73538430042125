import styled from 'styled-components'
import { breakpoints } from '@/styles/media'
import { AddressAutocomplete } from '@/components/Autocomplete/AddressAutocomplete'
import { Body2 } from '@/styles/typography'
import { routes } from '@shared/constants/routes'
import { useRouter } from 'next/router'
import { type ValidAddress } from '@/services/Addresses/types'
import { useEffect, useState } from 'react'
import { useHandleDialog } from '@/state/Dialog/useHandleDialog'
import { ModalType } from '@/constants/modalTypes'
import { useFetchShoppingStores } from '@/services/ShoppingStore/queries'
import { isValidAddress } from '@/components/AddressForm/validations'
import { type ShoppingStoreDetails } from '@/services/ShoppingStore/types'
import { Button } from '@shipt/design-system-buttons'
import { toSnakeCase } from '@/utils/formatter'
import { TextButtonLink } from '@/elements/Link/Buttons'
import { useGuestUserContext } from '@/context/GuestUserContext'
import { VisuallyHidden } from '@/elements/VisuallyHidden'
import { trackElementClickedForAddedAddress } from '@/analytics/address'
import { useMutation } from '@tanstack/react-query'
import { type AddressCheckData } from '@/components/CMS/types'
import { useCMSTrackingEvents } from '@/components/CMS/CMSContext'

export const AddressCheck = ({ data }: AddressCheckData) => {
  const { button_callout, button_treatment } = data ?? {}
  const { openDialog } = useHandleDialog()
  const [address, setAddress] = useState<ValidAddress>()
  const [errorMessage, setErrorMessage] = useState('')
  const router = useRouter()
  const { mutateAsync: setGuestAddress, isPending } = useMutation({
    mutationFn: useGuestUserContext()?.setGuestAddressCookie,
  })
  const { trackCMSElementViewed, trackCMSElementClicked } =
    useCMSTrackingEvents()

  useEffect(() => {
    trackCMSElementViewed({ content: 'address check input' })
  }, [trackCMSElementViewed])

  const handleDraftAddress = (draftAddress: ValidAddress) => {
    if (isValidAddress(draftAddress)) {
      setAddress(draftAddress)
      setErrorMessage('')
    }
  }

  const handleManualClick = () => {
    router.push(routes.GUEST_WELCOME.url)
  }

  const enteredZip = address?.zip_code
  const { fetchStores, isLoading: isLoadingStores } = useFetchShoppingStores()
  const isLoading = isLoadingStores || isPending

  const handleGuestUserAddress = async (validAddress: ValidAddress) => {
    trackElementClickedForAddedAddress()
    // Set the guest address cookie
    await setGuestAddress(validAddress)
    // Navigate to GHP
    router.push(routes.GLOBAL_HOMEPAGE.url)
  }

  const handleZipCode = async () => {
    const checkStores = (stores: ShoppingStoreDetails[]) => {
      if (stores.length && address) {
        handleGuestUserAddress(address)
      } else {
        if (!enteredZip) return
        openDialog(ModalType.ZIP_NOT_COVERED_MODAL, { zip: enteredZip })
        setAddress(undefined)
        setErrorMessage('Zip code address not in service area')
      }
    }
    if (address) {
      try {
        const stores = await fetchStores({ address })
        setErrorMessage('')
        checkStores(stores.delivery_stores)
      } catch (error) {
        checkStores([])
      }
    }
  }
  const handleGetStartedClick = () => {
    if (!address) {
      setErrorMessage('Please enter and select a valid address.')
    } else {
      handleZipCode()
      trackCMSElementClicked({
        type: 'button',
        content: toSnakeCase(button_callout),
        display_sublocation: 'hero',
      })
    }
  }

  return (
    <AddressWrapper>
      <AddressInputWrapper className="address-input-wrapper">
        <HomepageAddressInput
          id="address-autocomplete-homepage"
          onSubmit={handleDraftAddress}
        />
        <GetStartedButton
          loading={isLoading}
          disabled={isLoading}
          concept={button_treatment}
          onClick={handleGetStartedClick}
          aria-label={`${button_callout} - address input`}
        >
          {button_callout}
        </GetStartedButton>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </AddressInputWrapper>
      <NoAddressHeading color="gray600">
        Don't see your address?{' '}
        <AddManualButton
          onClick={handleManualClick}
          size="md"
          href={routes.GUEST_WELCOME.url}
        >
          Add<VisuallyHidden>address</VisuallyHidden>manually
        </AddManualButton>
      </NoAddressHeading>
    </AddressWrapper>
  )
}

const ErrorMessage = styled(Body2)`
  color: ${({ theme }) => theme.red500};
`

export const AddressWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: inherit;
`

const AddressInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.75rem;

  @media ${breakpoints.medium} {
    padding-right: 0;
  }
`

const HomepageAddressInput = styled(AddressAutocomplete)`
  max-width: 20rem;
  flex-grow: 1;
  flex-shrink: 0;

  @media ${breakpoints.small} {
    margin: 0 auto;
  }
`

const GetStartedButton = styled(Button)`
  display: flex;
  max-width: 20rem;
  flex-grow: 1;
  flex-shrink: 0;

  @media ${breakpoints.small} {
    width: 100%;
    margin: 0 auto;
  }
`

const NoAddressHeading = styled(Body2)`
  margin-top: 0.5rem;
  display: flex;
  margin-bottom: 1.5rem;
  margin-bottom: 0;

  @media ${breakpoints.small} {
    justify-content: center;
  }
`

const AddManualButton = styled(TextButtonLink)`
  gap: 0.25rem;
  margin-left: 0.5rem;
`
